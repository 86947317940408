import shopifySparkV2Defaults from '../shopify-spark-v2/config.js';

const wheelsFields = shopifySparkV2Defaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifySparkV2Defaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const isVehicleLanding = !!globalThis.Convermax.config?.isVehicleLanding;

const categorySelectionPageField = 'category';

const showRelatedItems = (items, tab) => {
  const tabDiameter = tab.getAttribute('data-diameter');

  tab.classList.add('active');
  window.document
    .querySelectorAll(`.cm_related-items .nav-link:not([data-diameter="${tabDiameter}"])`)
    ?.forEach((tab) => {
      tab.classList.remove('active');
    });

  items.forEach((item) =>
    item.classList.toggle('cm_hide', item.getAttribute('data-diameter') !== tabDiameter),
  );
};

const onRelatedItemsRendered = () => {
  const widget = window.document.querySelector('.cm_related-items');

  if (!widget) {
    return;
  }

  const items = [...widget.querySelectorAll('tbody > tr')];

  if (!items) {
    return;
  }

  const tabsContainer = widget.querySelector('.nav-tabs');

  const uniqueDiameters = new Set(items.map((e) => e.getAttribute('data-diameter')));
  [...uniqueDiameters]
    // ['17"', '20"', '18"'] -> ['17"', '18"', '20"']
    .filter(Boolean)
    .sort((a, b) => a.replace('"', '') - b.replace('"', ''))
    .forEach((diameter) => {
      const tab = window.document.createElement('li');
      tab.className = 'nav-item';
      tab.innerHTML = `<a class="nav-link" data-diameter="${diameter}">${diameter}"</a>`;
      tabsContainer.appendChild(tab);
    });

  showRelatedItems(items, widget.querySelector('.nav-link'));

  widget.querySelectorAll('.nav-link').forEach((e) => {
    e.addEventListener('click', () => showRelatedItems(items, e));
  });
};

window.Convermax.getFacetTilesUrl = (href, term) => {
  if (window.location.pathname === '/collections/vendors') {
    // for vendors on base shopify collection like
    // https://caliraisedoffroad.com/collections/vendors?q=1Life%20Trauma
    const uriVendor = window.encodeURIComponent(new URLSearchParams(window.location.search).get('q'));
    return `/collections/vendors?q=${uriVendor}&Facet=category/${term}`;
  } else if (window.isVendorCollection) {
    // for vendors with collection like
    // https://caliraisedoffroad.com/collections/expedition-essentials
    return `${window.location.pathname}?Facet=category/${term}`;
  }

  return href;
};

export default {
  includes: ['shopify-spark-v2'],
  ...shopifySparkV2Defaults,
  SearchRequestDefaults: {
    ...shopifySparkV2Defaults.SearchRequestDefaults,
    extra: {
      ...shopifySparkV2Defaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  fitmentSearch: {
    ...shopifySparkV2Defaults.fitmentSearch,
    forceVehicleSelection: true,
    categorySelectionPages: [
      ...shopifySparkV2Defaults.fitmentSearch.categorySelectionPages,
      {
        pathname: isVehicleLanding ? window.location.pathname : null,
        field: categorySelectionPageField,
      },
    ],
    hideVehicleFromUrl: true,
  },
  Widgets: [
    ...shopifySparkV2Defaults.Widgets.filter(
      (w) => !['HomeVehicleWidget', 'SearchVehicleWidget', 'CategoryPage'].includes(w.name),
    ),
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { selector: '#cm_collection-page', class: 'cm_SearchPageCategory cm_main-content' },
      template: 'MainContent',
      visibleIf: () => !isVehicleLanding,
    },
    {
      name: 'VehicleLandingPageCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_collection-page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => isVehicleLanding,
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      facetField: 'category',
      visibleIf: () => !isVehicleLanding,
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: { replace: '.home-ymm-bar #ymm-tab .cm_vehicle-widget' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: { firstChildOf: '.home-ymm-bar #wheel-tab' },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: { firstChildOf: '.home-ymm-bar #tire-tab' },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'RelatedWheels',
      type: 'RelatedItems',
      location: '#cm_related-wheels',
      template: 'product/relatedWheels',
      count: 20,
      getSelection: (itemData) => {
        const field = 'wheel_model';
        const value = itemData[field];
        if (value) {
          return [{ field, term: value }];
        }
      },
      onItemsRendered: onRelatedItemsRendered,
      sort: 'wheel_diameter',
    },
  ],
};
