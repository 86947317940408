import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/shopify-spark-v2/Templates/facetTiles/hierarchicalContainer.rt'
import TemplatefacetTilesalphabeticalContainer from 'Stores/_default-store/Templates/facetTiles/alphabeticalContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/shopify-spark-v2/Templates/fitmentSearch/singleVehicleGarage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTableTabDesktop from 'Stores/shopify-spark-v2/Templates/fitmentSearch/fitmentTableTabDesktop.rt'
import TemplatefitmentSearchfitmentTableTabMobile from 'Stores/shopify-spark-v2/Templates/fitmentSearch/fitmentTableTabMobile.rt'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/shopify-spark-v2/Templates/product/relatedItems.rt'
import { ProductData } from 'Components'
import TemplateproductwheelTireProductAttributes from 'Stores/shopify-spark-v2/Templates/product/wheelTireProductAttributes.rt'
import { FacetBar } from 'Components'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import TemplateMainContent from 'Stores/cali-raised-offroad/Templates/MainContent.rt'
import TemplatefacetTilessubtitles from 'Stores/cali-raised-offroad/Templates/facetTiles/subtitles.rt'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { RequestPanel } from 'Components'
import TemplateHomeWheelTireBar from 'Stores/_default-store/Templates/HomeWheelTireBar.rt'
import TemplateproductrelatedWheels from 'Stores/cali-raised-offroad/Templates/product/relatedWheels.rt'

export const compMap = {
  SearchPage,
FacetPanel,
FacetTiles,
SearchBox,
SearchBoxDialogButton,
Garage,
SingleVehicleGarage,
VehicleWidget,
VerifyFitment,
FitmentTable,
RelatedItems,
ProductData,
FacetBar,
SearchHeader,
SearchResult,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog,
RequestPanel
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'FacetPanelContainer': TemplateFacetPanelContainer,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'facetTiles/alphabeticalContainer': TemplatefacetTilesalphabeticalContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTableTabDesktop': TemplatefitmentSearchfitmentTableTabDesktop,
'fitmentSearch/fitmentTableTabMobile': TemplatefitmentSearchfitmentTableTabMobile,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'product/relatedItems': TemplateproductrelatedItems,
'product/wheelTireProductAttributes': TemplateproductwheelTireProductAttributes,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'MainContent': TemplateMainContent,
'facetTiles/subtitles': TemplatefacetTilessubtitles,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'HomeWheelTireBar': TemplateHomeWheelTireBar,
'product/relatedWheels': TemplateproductrelatedWheels
};